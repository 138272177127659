import { createSelector } from 'reselect';
import { intersection } from 'underscore';
import formatName from 'I18n/utils/formatName';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getRenderLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { getIsBlogListingPage, getIsLandingPage, getIsSitePage, getIsBlogPost, getIsEmail } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getPortalHasSitePagesHsSitesDomainAccess, getPortalHasBlogHsSitesDomainAccess, getHasLandingPagesAutoTranslateAccess, getHasSitePagesAutoTranslateAccess, getHasBlogPostsAutoTranslateAccess } from './portalSelectors';
import { SUPPORTED_HUBS, SUPPORTED_HUBS_REQUIRED_SCOPES } from 'collaboration-sidebar';
import { getFeatureFlag, getCanUseContentEmbedFeatureFlag
// @ts-expect-error not yet typed
} from './featureFlagsSelectors';
import { FEATURE_FLAGS } from 'ContentEditorUI/redux/constants';
import { FEATURES } from 'collaboration-sidebar';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './po... Remove this comment to see the full error message
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
export const getAuth = basicSelector(state => state.auth);
export const getUser = createSelector([getAuth], auth => auth.user);
export const getGates = createSelector([getAuth], auth => auth.gates);
export const getScopes = createSelector([getUser], user => user.scopes);
export const getRoles = createSelector([getUser], user => user.roles);
export const getUserTeams = createSelector([getUser], user => (user.teams || []).filter(team => team.my_team).map(team => team.id));
export const createHasScopeSelector = scopeToCheck => createSelector([getScopes], scopes => scopes.indexOf(scopeToCheck) > -1);
export const createHasScopesSelector = scopesToCheck => createSelector([getScopes], scopes => {
  return intersection(scopes, scopesToCheck).length === scopesToCheck.length;
});
export const createHasGateSelector = gateToCheck => createSelector([getGates], gates => gates.indexOf(gateToCheck) > -1);

// Never check roles - for access use gates or scopes.
export const createHasRoleSelector = roleToCheck => createSelector([getRoles], roles => roles.indexOf(roleToCheck) > -1);
export const getPortalDetails = createSelector([getAuth], auth => auth.portal);
export const getPortalId = createSelector([getPortalDetails], portal => portal.portal_id);
export const getPortalCreatedAt = createSelector([getPortalDetails], portal => portal.created_at);
export const getIsSuperAdmin = createHasScopeSelector('super-admin');
export const getIsSuperUser = createHasScopeSelector('super-user');
export const getIsUngatedForDisableNonMarketableListCreation = createHasGateSelector('Email:DisableNonMarketableListCreation');
export const getCanCreateNonMarketableListAsSuper = createSelector([getIsUngatedForDisableNonMarketableListCreation, getIsSuperUser, getIsSuperAdmin], (isUngatedForDisableNonMarketableListCreation, isSuperUser, isSuperAdmin) => {
  return !isUngatedForDisableNonMarketableListCreation || isUngatedForDisableNonMarketableListCreation && (isSuperAdmin || isSuperUser);
});
export const getUserLocale = createSelector([getUser], user => user.locale);
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserFirstName = createSelector([getUser], user => user.first_name);
export const getUserFullName = createSelector([getUser], user => formatName({
  firstName: user.first_name,
  lastName: user.last_name
}));
export const getUserId = createSelector([getUser], user => user.user_id);
export const getIsUserJitad = createSelector([getUser], user => user.scopes.indexOf('jita-user') > -1);
export const getHasCrmScope = createHasScopeSelector('crm-product');
export const getHasCampaignsReadAccess = createHasScopeSelector('campaigns-access');
export const getHasCampaignsWriteAccess = createHasScopeSelector('campaigns-write');
export const getHasSubscriptionsAccess = createHasScopeSelector('subscriptions-access');
export const getHasSubscriptionsReadAccess = createHasScopeSelector('subscriptions-read');
export const getHasEmailAccessScope = createHasScopeSelector('email-access');
export const getHasEmailRecipientFatigueSuppressionReadScope = createHasScopeSelector('email-recipient-fatigue-suppression-read');
export const getHasEmailCoreApiAccessScope = createHasScopeSelector('email-core-api-access');
export const getHasLandingPagesAccess = createHasScopeSelector('landingpages-access');
export const getHasLandingPagesMultiLangAccess = createHasScopeSelector('landingpages-multilanguage');
export const getHasSitePagesMultiLangAccess = createHasScopeSelector('sitepages-multilanguage');
export const getHasLegacyCtaAccess = createHasScopeSelector('cta-access');
export const getHasWebInteractivesAccess = createHasScopeSelector('calls-to-action-read');
export const getHasCtaAccess = createSelector([getHasLegacyCtaAccess, getHasWebInteractivesAccess], (legacyAccess, webInteractivesAccess) => legacyAccess || webInteractivesAccess);
export const getHasWorkflowsTicketsAccessScope = createHasScopeSelector('workflows-tickets-access');
export const getDomainPageAttachedStylesheetAccess = createHasScopeSelector('domain-page-attached-stylesheet-access');
export const getHasWorkflowsEmailAccessScope = createHasScopeSelector('email-type-workflows-access');
export const getHasWorkflowsEmailWriteAccessScope = createHasScopeSelector('workflows-edit-team-owned');
export const getHasWorkflowsDealsAccessScope = createHasScopeSelector('workflows-platform-deals-access');
export const getHasFormsEmailWriteAccessScope = createHasScopeSelector('forms-write');
export const getHasWorkflowsOrFormsEmailWriteAccessScope = createSelector([getHasWorkflowsEmailWriteAccessScope, getHasFormsEmailWriteAccessScope], (hasWorkflosWriteAccess, hasFormsWriteAccess) => hasWorkflosWriteAccess || hasFormsWriteAccess);
export const getHasLandingPagesSmartContentScope = createHasScopeSelector('landingpages-smartcontent');
export const getHasSitePagesSmartContentScope = createHasScopeSelector('sitepages-smartcontent');
export const getHasBlogPostsSmartContentScope = createHasScopeSelector('blog-posts-smartcontent');
export const getHasPartitionedSmartContentScopes = createSelector([getHasLandingPagesSmartContentScope, getHasSitePagesSmartContentScope, getHasBlogPostsSmartContentScope, getIsLandingPage, getIsSitePage, getIsBlogPost, getIsBlogListingPage], (hasLandingPagesSmartContentScope, hasSitePagesSmartContentScope, hasBlogPostsSmartContentScope, isLandingPage, isSitePage, isBlogPost, isBlogListingPage) => {
  const hasContentTypeWithContentScope = isLandingPage && hasLandingPagesSmartContentScope || isSitePage && hasSitePagesSmartContentScope || (isBlogListingPage || isBlogPost) && hasBlogPostsSmartContentScope;
  return hasContentTypeWithContentScope;
});
export const getHasGenericSmartContentScope = createHasScopeSelector('smartcontent');
export const getHasSmartContentScope = createSelector([getIsLandingPage, getIsSitePage, getIsBlogPost, getIsBlogListingPage, getHasPartitionedSmartContentScopes, getHasGenericSmartContentScope], (isLandingPage, isSitePage, isBlogPost, isBlogListingPage, hasPartitionedSmartContentScopes, hasGenericSmartContentScope) => {
  const isPartitionedSmartContentEnabledContentType = isSitePage || isLandingPage || isBlogPost || isBlogListingPage;
  return isPartitionedSmartContentEnabledContentType ? hasPartitionedSmartContentScopes : hasGenericSmartContentScope;
});
export const getHasWorkflowsQuotesAccessScope = createHasScopeSelector('workflows-quotes-access');
export const getHasDesignManagerAccessScope = createHasScopeSelector('design-manager-access');
export const getHasMABAccess = createHasScopeSelector('content-mab-testing');
export const isSocialAdminUser = createHasScopeSelector('social-all-accounts-configure');
export const isSocialAllAccountsUser = createHasScopeSelector('social-shared-accounts-publish');
export const getHasSocialAccess = createHasScopeSelector('social-access');
export const getHasTopicClusterAccess = createHasScopeSelector('topic-clusters-access');
export const getHasPermissionsToCreateOrEditABTest = createHasScopeSelector('content-ab-testing');
export const getHasHubspotVideoAccess = createHasScopeSelector('marketing-video');
export const getHasEitherTestCreationAccess = createSelector([getHasMABAccess, getHasPermissionsToCreateOrEditABTest], (hasMABAccess, hasABAccess) => hasMABAccess || hasABAccess);
export const getHasGlobalContentEditorAccess = createHasScopeSelector('global-content-write-access');
export const getCanContactSupport = createHasScopeSelector('zorse-webticket');
export const getHasAdvancedLandingPagesWriteScope = createHasScopeSelector('landingpages-advanced-write');
export const getHasSitePagesWriteScope = createHasScopeSelector('pages-write');
export const getHasBlogListingPagesWriteScope = createHasScopeSelector('blog-listing-page-write');
export const getHasThemeGlobalSettingsScope = createHasScopeSelector('website-theme-write-access');
export const getHasMultiLangAccess = createHasScopeSelector('cms-multi-language');
export const getIsUngatedToMergeTemplateSchemaBodyIntoContentBody = createHasGateSelector('ContentEditorUI:MergeTemplateSchemaBodyIntoContentBody');
export const getIsUngatedToRepositionPostBodyModules = createHasGateSelector('Blog:RepositionPostBodyModules');
export const getIsUngatedForDataTab = createHasGateSelector('ContentEditorUI:DataTab');
export const getHasTinyMCEParsedFonts = createHasGateSelector('ContentEditorUI:TinyMCEParsedFonts');
export const getHasTinyMCEParsedFontsEnabled = createHasGateSelector('ContentEditorUI:TinyMCEParsedFontsEnabled');
export const getIsUngatedForInlineDataTokenEditing = createHasGateSelector('ContentEditorUI:InlineDataTokenEditing');
export const getIsUngatedForSCPWidgetEditor = createHasGateSelector('StructuredContent:CaseStudyWidgetEditor');
export const getIsUngatedForGlobalOwnerDefaultsOnly = createHasGateSelector('Messaging:GlobalOwnerDefaultsOnly');
export const getHasMeetingsPersonalCreateAccess = createHasScopeSelector('meetings-personal-link-create');
export const getHasMeetingsTeamCreateAccess = createHasScopeSelector('meetings-team-link-create');
export const getHasBrandVoiceAccess = createHasScopeSelector('brand-voice');
export const getHasUniversalEditAccess = createHasScopeSelector('meetings-edit-all-links');
export const getIsUngatedForContentTreeSidebar = createHasGateSelector('Email:ContentTreeSidebar');
export const getHasBlogSchedulingUpdateAccess = createSelector([getScopes], scopes => scopes.includes('blog-publish-scheduled-update'));
export const getHasPagesSchedulingUpdateAccess = createSelector([getIsSitePage, getScopes], (isSitePage, scopes) => scopes.includes(`${isSitePage ? 'pages' : 'landingpages'}-publish-scheduled-update`));
export const getHasPartnerMatchingAppAccess = createSelector([getScopes, getRenderLanguage], (scopes, renderLanguage) => scopes.indexOf('partner-matching-app-access') > -1 && renderLanguage === 'en');
export const getHasAccountTrustScope = createHasScopeSelector('landingpages-trusted');
export const getHasAccessToUrlRedirects = createHasScopeSelector('cms-url-redirects-read');
export const getIsUngatedForMergeInDefaultValues = createHasGateSelector('ContentRendering:MergeInDefaultValues');
export const getIsUngatedForContentTypes = createHasGateSelector('CMv2:UseContentTypes');
export const getHasPaymentsReadAccess = createHasScopeSelector('payment-links-read');
export const getIsUngatedForModuleVisibility = createHasGateSelector('ContentEditorUI:VisibilityModule');
export const getIsUngatedForModuleInheritanceIcon = createHasGateSelector('CMv2:DisplayInheritanceIcon');
export const getIsUngatedForNewEntryPoint = createHasGateSelector('ContentEditorUI:EntryPoint');
export const getHasPersonalizationAccess = createHasScopeSelector('personalization-access');
export const getHasWebsitePagesNoBranding = createHasScopeSelector('website-pages-no-branding');
export const getHasLandingPagesNoBranding = createHasScopeSelector('landingpages-no-branding');
export const getHasBlogPagesNoBranding = createHasScopeSelector('blog-pages-no-branding');

// The Remove Branding popups should only appear if they have the CMS Free product.
// We can check this by looking for the landingpages-no-branding,
// website-pages-no-branding, and blog-pages-no-branding scopes.
export const getShouldShowRemoveBrandingMessage = createSelector([getIsBlogListingPage, getIsBlogPost, getHasBlogPagesNoBranding, getIsLandingPage, getHasLandingPagesNoBranding, getIsSitePage, getHasWebsitePagesNoBranding], (isBlogListingPage, isBlogPost, hasBlogPagesNoBranding, isLandingPage, hasLandingPagesNoBranding, isSitePage, hasWebsitePagesNoBranding) => (isBlogListingPage || isBlogPost) && !hasBlogPagesNoBranding || isLandingPage && !hasLandingPagesNoBranding || isSitePage && !hasWebsitePagesNoBranding);
export const getIsUserUngatedForPatagonia = createHasGateSelector('ContentEditorUI:PatagoniaRedesign');
export const getIsUngatedForDndAreasInCustomEditor = createHasGateSelector('Email:DndAreasInCustomEditor');
export const getIsProductIncompatibleWithPrerendering = createSelector([getHasAdvancedLandingPagesWriteScope, getPortalHasSitePagesHsSitesDomainAccess, getPortalHasBlogHsSitesDomainAccess, getIsLandingPage, getIsSitePage, getIsBlogPost], (hasAdvancedLandingPagesWriteScope, portalHasSitePagesHsSitesDomainAccess, portalHasBlogHsSitesDomainAccess, isLandingPage, isSitePage, isBlogPost) => {
  if (isLandingPage) return hasAdvancedLandingPagesWriteScope;
  if (isSitePage) return portalHasSitePagesHsSitesDomainAccess;
  if (isBlogPost) return portalHasBlogHsSitesDomainAccess;
  return false;
});
export const getHasDndTemplateAccess = createHasScopeSelector('cms-dnd-templates');
const getHasCMSHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.CMS_HUB]);
const getHasMarketingHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.MARKETING_HUB]);
export const getHasCommenting = createSelector([getIsEmail, getHasCMSHubCommenting, getHasMarketingHubCommenting], (isEmail, hasCMSHubCommenting, hasMarketingHubCommenting) => isEmail ? hasMarketingHubCommenting : hasCMSHubCommenting);
export const getHasAutotranslateAccess = createSelector([getIsLandingPage, getIsSitePage, getIsBlogPost, getHasLandingPagesAutoTranslateAccess, getHasSitePagesAutoTranslateAccess, getHasBlogPostsAutoTranslateAccess], (isLandingPage, isSitePage, isBlogPost, hasLandingPagesAutoTranslateAccess, hasSitePagesAutoTranslateAccess, hasBlogPostsAutoTranslateAccess) => {
  if (isLandingPage) {
    return hasLandingPagesAutoTranslateAccess;
  } else if (isSitePage) {
    return hasSitePagesAutoTranslateAccess;
  } else if (isBlogPost) {
    return hasBlogPostsAutoTranslateAccess;
  }
  return false;
});
export const getHasPrivateContentAccess = createHasScopeSelector('marketing-content-restricted-write');
export const getHasLandingPagesPrivateContentPublishScope = createHasScopeSelector('landingpages-private-content-publish');
export const getHasSitePagesPrivateContentPublishScope = createHasScopeSelector('pages-private-content-publish');
export const getHasBlogPrivateContentPublishScope = createHasScopeSelector('blog-private-content-publish');
export const getHasPrivateContentPublishScopeForContentType = createSelector([getIsLandingPage, getIsSitePage, getIsBlogPost, getHasLandingPagesPrivateContentPublishScope, getHasSitePagesPrivateContentPublishScope, getHasBlogPrivateContentPublishScope], (isLandingPage, isSitePage, isBlogPost, hasLandingPagesPrivateContentPublishScope, hasSitePagesPrivateContentPublishScope, hasBlogPrivateContentPublishScope) => isLandingPage && hasLandingPagesPrivateContentPublishScope || isSitePage && hasSitePagesPrivateContentPublishScope || isBlogPost && hasBlogPrivateContentPublishScope);
export const getIsUngatedForPatagonia = createSelector([getIsUserUngatedForPatagonia], isUserUngatedForPatagonia => {
  return isUserUngatedForPatagonia;
});
export const getIsUngatedForFixedLayoutSections = createHasGateSelector('CMS:FixedLayoutSections');

// Gate for normalizing old background styles to new background layer styles
export const getIsUngatedForBackgroundLayersGenerateStyles = createHasGateSelector('Cms:GenerateBackgroundLayerStyles');

// Gate for background layers UI, requires Cms:GenerateBackgroundLayerStyles to use
export const getIsUngatedForBackgroundLayers = createHasGateSelector('ContentEditorUI:BackgroundLayers');
export const getHasPreventTinymceTransformsOnUndo = createHasGateSelector('ContentEditorUI:PreventTinymceTransformsOnUndo');
export const getIsUngatedForCMSGenerativeImages = createHasGateSelector('CMS:GenerativeAIImages');
export const getIsUngatedForModuleFieldEditing = createHasGateSelector('ContentEditorUI:InlineModuleFieldEditing');
export const getIsUngatedForAutotranslation = createHasGateSelector('CMS:Autotranslation');
export const getIsUngatedForContentRemixQuickMix = createHasGateSelector('ContentRemix:QuickMix');
export const getHasContentRemixWriteAccess = createHasScopeSelector('content-remix-write');
export const getPortalIsUngatedForPublishPrivateContentPermissions = createHasGateSelector('UserManagement:PublishPrivateContent');
export const getUserHasPublishPrivateContentAccess = createSelector([getHasPrivateContentPublishScopeForContentType, getPortalIsUngatedForPublishPrivateContentPermissions], (hasPrivateContentPublishScopeForContentType, portalIsUngatedForPublishPrivateContentPermissions) => portalIsUngatedForPublishPrivateContentPermissions ? hasPrivateContentPublishScopeForContentType : true);
export const getCanUseQuickSpot = createSelector([getIsUngatedForPatagonia], isUngatedForPatagonia => {
  return isUngatedForPatagonia && getFeatureFlag(FEATURE_FLAGS.quickSpot);
});
export const getIsUngatedForNewBlogOnboardingFlow = createHasGateSelector('Blog:NewOnboardingFlow');
export const getIsUngatedForTeamPartitioningLists = createHasGateSelector('teams-partitioning-lists');
export const getIsUngatedForInPageComment = createHasGateSelector('ContentEditorUI:CommentPopOver');
export const getIsUngatedForCommentPopOver = createSelector([getIsUngatedForPatagonia, getIsUngatedForInPageComment], (isUngatedForPatagonia, isUngatedForInPageComment) => {
  const collabSidebarFeature = getFeatureFlag(FEATURE_FLAGS.collaborationSidebar, false);
  const isDeepCommentingEnabled = collabSidebarFeature && collabSidebarFeature.enabledFeatures && collabSidebarFeature.enabledFeatures.indexOf(FEATURES.DEEP_COMMENTING) !== -1;

  //check if has permission to save page which is triggered when comment is created
  const hasPermissionsToSave = EditorConfigSingleton.getHasPermissionsToSave();
  return hasPermissionsToSave && isUngatedForPatagonia && isUngatedForInPageComment && isDeepCommentingEnabled;
});
export const getHasContentEmbedReadScope = createHasScopeSelector('content-embed-read');
export const getIsUngatedForEmbeds = createHasGateSelector('Cms:WordpressEmbed');
export const getCanUseContentEmbedFeature = createSelector([getIsUngatedForEmbeds, getCanUseContentEmbedFeatureFlag], (isUngatedForEmbeds, canUseContentEmbedFeatureFlag) => isUngatedForEmbeds && canUseContentEmbedFeatureFlag);
export const getHasContentEmbedAccess = createSelector([getHasContentEmbedReadScope, getCanUseContentEmbedFeature], (hasContentEmbedReadScope, canUseContentEmbedFeature) => hasContentEmbedReadScope && canUseContentEmbedFeature);
export const getIsUngatedForIndividualPageLimits = createHasGateSelector('CMSPublishing:IndividualPageLimits');
const getIsUngatedForAudioNarration = createHasGateSelector('Blog:AudioNarration');
const getHasAudioNarrationScope = createHasScopeSelector('content-narration-access');
const getHasImageGenerationAccessScope = createHasScopeSelector('image-generation-access');
export const getHasImageGenerationAccess = createSelector([getIsUngatedForCMSGenerativeImages, getHasImageGenerationAccessScope], (isUngated, hasScope) => isUngated && hasScope);
export const getHasAudioNarrationAccess = createSelector([getIsUngatedForAudioNarration, getHasAudioNarrationScope], (isUngated, hasScope) => isUngated && hasScope);
export const getIsUngatedForModuleVersioning = createHasGateSelector('ModuleVersioningUI');
export const getIsUngatedForUnifiedPublishButton = createHasGateSelector('ContentEditorUI:UnifiedPublishButton');
export const getIsUngatedForCopilot = createHasGateSelector('Chatspot:Widget:Beta');
const getIsUngatedForCollaborateWithCopilotGate = createHasGateSelector('Copilot:Collaborate');
export const getIsUngatedForCopilotCollaboration = createSelector([getIsUngatedForCopilot, getIsUngatedForCollaborateWithCopilotGate], (isUngatedForCopilot, isUngatedForCollaborateWithCopilotGate) => isUngatedForCopilot && isUngatedForCollaborateWithCopilotGate);
export const getHasContentManagementSavedViews = createHasScopeSelector('content-management-saved-views');
export const getIsUngatedForTallestFractionalElementHeight = createHasGateSelector('ContentEditorUI:TallestFractionalElementHeight');
export const getIsUngatedForImmerLayoutSectionDndReducer = createHasGateSelector('ContentEditorUI:ImmerLayoutSectionDndReducer');
export const getIsUngatedForBrandVoiceV2Prompt = createHasGateSelector('BrandVoice:UseV2Prompt');
export const getIsUngatedForUnifiedEditorCsat = createHasGateSelector('ContentEditorUI:UnifiedCSAT');
export const getIsUngatedForCustomBreakpoints = createHasGateSelector('CMS:CustomBreakpoints');