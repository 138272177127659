// NOTE, all of this file is shared between the hscontentgeneration plugin and hsgenandselect plugin
export const CONTENT_ASSISTANT_SETTINGS = 'hsContentAssistant';
export const hsContentAssistantConfig = {
  IS_DISABLED: 'isDisabled',
  SLASH_COMMANDS: 'slashCommands',
  SELECTION_COMMANDS: 'selectionCommands',
  BRAND_VOICE_OPTIONS: 'brandVoiceOptions',
  COPILOT_COLLAB_CONTEXT_VALUE: 'copilotCollabContextValue',
  // TODO temporary to prevent breaking builds (TF)
  IS_COPILOT_COLLABORATION_ENABLED: 'isCopilotCollaborationEnabled'
};
export const SUPER_ADMIN_SCOPE = 'super-admin';
export const TRIGGER = '/';

// @TODO: Remove once IMAGE is injected in hsContentAssistant.slashCommands config everywhere
export const ADD_AI_IMAGE_COMMAND = {
  id: 'generateimage',
  labelKey: 'TinymcePlugins.hsimage.slashCommands.ADD_AI_IMAGE.label'
};