export const HS_CORE = 'hscore';
export const HS_IMAGE = 'hsimage_v3';
export const HS_IMAGE_INPAGE = 'hsimage_v3_inpage';
export const HS_LINK = 'hslink_v2_inapp';
export const HS_LINK_INPAGE = 'hslink_v2_inpage';
export const HS_PERSONALIZE = 'hspersonalize_v2';
export const HS_DATA_TOKEN = 'hsdatatokens';
export const HS_FORMATTING = 'hsformatting';
export const HS_CTA = 'hscta_v2';
export const HS_CTA_INPAGE = 'hscta_v2_inpage';
export const HS_CODE = 'hscode_v2';
export const HS_CODE_INPAGE = 'hscode_v2_inpage';
export const HS_MORE = 'hsmore_v2';
export const HS_USAGE = 'hsusage';
export const HS_MEDIA = 'hsmedia';
export const HS_MEDIA_INPAGE = 'hsmedia_inpage';
export const HS_VIDEO_V2 = 'hsvideo_v2';
export const HS_VIDEO_V2_INPAGE = 'hsvideo_v2_inpage';
export const HS_COLOR = 'hscolor';
export const HS_RSS_TOKEN = 'hsrsstoken_v2';
export const HS_PASTE = 'hspaste';
export const HS_EMAIL_EDITOR = 'hsemaileditor';
export const HS_EMOJI = 'hsemoji';
export const HS_EMOJI_INPAGE = 'hsemoji_inpage';
export const HS_SNIPPET = 'hssnippet';
export const HS_TABLE_V2 = 'hstable2';
export const HS_SETTING_PASSTHROUGH = 'hssettingpassthrough';
export const HS_I18N = 'hsi18n';
export const HS_NEWLINE = 'hsnewline';
export const HS_PROTECT = 'hsprotect';
export const HS_LISTS = 'hslists';
export const HS_VISUAL_BLOCKS = 'hsvisualblocks';
export const HS_ANCHOR = 'hsanchor';
export const HS_TRANSFORM_ALERT = 'hstransformalert';
export const HS_TRANSFORM_ALERT_INPAGE = 'hstransformalert_inpage';
export const HS_SANITIZE = 'hssanitize';
export const HS_CALLOUT = 'hscallout';
export const HS_CHARMAP = 'hscharmap';
export const HS_DIRECTIONALITY = 'hsdirectionality';
export const HS_FIND_REPLACE = 'hsfindreplace';
export const HS_HR = 'hshr';
export const HS_NONBREAKING = 'hsnonbreaking';
export const HS_MODULE_INSERTION = 'hsmoduleinsertion';
export const HS_GOOGLE_DOCS = 'hsgoogledocs';
export const HS_GOOGLE_DOCS_INPAGE = 'hsgoogledocs_inpage';
export const HS_ICON = 'hsicon';
export const HS_ESCAPE_NEWLINES = 'hsescapenewlines';
export const HS_CONTENT_GENERATION = 'hscontentgeneration';
export const HS_CONTENT_GENERATION_INPAGE = 'hscontentgeneration_inpage';
export const HS_AUTO_COMPLETE = 'hsautocomplete';
// TODO rename
export const HS_GEN_AND_SELECT = 'hsgenandselect';