export const BOGUS_DATA_ATTR = 'data-mce-bogus';
export const BOGUS_BR = `<br ${BOGUS_DATA_ATTR}="1">`;
export const isElementOrChildOf = (el, findEl) => {
  if (!el || !findEl) {
    return false;
  }
  if (el === findEl) {
    return true;
  }
  do {
    if (el === findEl) {
      return true;
    }
    el = el.parentElement;
  } while (el);
  return false;
};
export const getFlatChildNodes = node => {
  const childNodes = [];
  function getChildNodes(parent) {
    const children = [...parent.childNodes];
    children.forEach(child => {
      childNodes.push(child);
      if (child.childNodes.length) {
        getChildNodes(child);
      }
    });
  }
  if (node.childNodes.length) {
    getChildNodes(node);
  }
  return childNodes;
};
export const isEmptyParagraph = el => {
  return el && el.nodeName === 'P' && el.childElementCount === 1 && el.firstElementChild && el.firstElementChild.nodeName === 'BR';
};
const isLeaf = el => {
  return el && !el.hasChildNodes();
};
const getInnerMostLeaf = (el, whichChild) => {
  let node = el;
  while (!isLeaf(node)) {
    node = node[whichChild];
  }
  return node;
};
export const getFirstInnerMostLeaf = el => {
  return getInnerMostLeaf(el, 'firstChild');
};
export const getLastInnerMostLeaf = el => {
  return getInnerMostLeaf(el, 'lastChild');
};
export const getSingleInnerMostLeaf = el => {
  let node = el;
  while (!isLeaf(node)) {
    if (el.childElementCount > 1) return null;
    node = node.firstChild;
  }
  return node;
};

/**
 *
 * @param {Node} tree wrapping node of tree
 * @param {Node} node node to inserted at inner most leaf of tree
 */
export const insertAtInnerMostLeaf = (tree, node) => {
  const innerMostLeaf = getSingleInnerMostLeaf(tree);
  if (innerMostLeaf) {
    innerMostLeaf.appendChild(node);
  }
};

/**
 *
 * @param {Node} tree wrapping node of tree
 * @param {Node} node node to replace innermost leaf of tree
 */
export const replaceInnerMostLeaf = (tree, node) => {
  const innerMostLeaf = getSingleInnerMostLeaf(tree);
  if (innerMostLeaf && innerMostLeaf.parentNode) {
    innerMostLeaf.parentNode.replaceChild(node, innerMostLeaf);
  }
};

// This util inserts delimiters into the existing DOM around the current selection, then
// asks Tiny to give us the content of the editor editor (including the delimiters) as text
// before removing the delimiter text nodes and putting the DOM back into the same state
// we started with.
export const getAnnotatedSelectionContextAsPlainText = (editor, range) => {
  let textContext = '';
  editor.undoManager.ignore(() => {
    const duplicatedRange = range.cloneRange();
    const prefix = document.createTextNode('<<');
    const suffix = document.createTextNode('>>');
    duplicatedRange.insertNode(prefix);
    duplicatedRange.collapse(false);
    duplicatedRange.insertNode(suffix);
    textContext = editor.getContent({
      format: 'text'
    });
    prefix.remove();
    suffix.remove();
    range.commonAncestorContainer.normalize();
  });
  return textContext;
};

// This util calls the above util, but it also finds and removes the slash command that is (temporarily) present in the content.
export const getAnnotatedSelectionContextAsPlainTextAndRemovePrecedingSlashCommand = (editor, range) => {
  const textContext = getAnnotatedSelectionContextAsPlainText(editor, range);
  const newContextWithoutSlashCommand = textContext.replace(/<<(.*)>>/g, '<<>>');
  return newContextWithoutSlashCommand;
};