import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["promptThread"];
import I18n from 'I18n';
import { getCopilotActionFromContentAssistantData, getContentAssistantContextAsStringForCopilot } from 'copilot-toolkit/utils/contentAssistantUtils';
import { getContentLangauge, getContextFromTinymceForCommand, getBrandVoiceOptionsForCommand, triggerFileManagerImageGeneration } from 'tinymce-plugins/hsgenandselect/utils';
export function handleSlashOrHighlightCommand(editor, command, commandType, currentContextRef, sendActionFromAppToCopilot) {
  // Handle AI image gen command that doesn't go to copilot
  if ('id' in command && command.id === 'generateimage') {
    triggerFileManagerImageGeneration(editor);
    return;
  }
  const contentLanguage = getContentLangauge(editor);
  const {
    currentSelection,
    hasSelection,
    editorContext,
    precedingText,
    followingText
  } = getContextFromTinymceForCommand(editor, command, commandType);
  const brandVoiceOptions = getBrandVoiceOptionsForCommand(editor, command);
  forwardCommandToCopilot(command, editorContext, hasSelection, currentSelection, precedingText, followingText, currentContextRef, sendActionFromAppToCopilot, brandVoiceOptions, contentLanguage);
}
export function forwardCommandToCopilot(command, editorContext, hasSelection, currentSelection, precedingText, followingText, currentContextRef, sendActionFromAppToCopilot, brandVoiceOptions, contentLanguage) {
  const contentAssistantData = {
    commandId: command.commandId || command.id,
    parameters: formatParameters(command.parameters),
    editorContext,
    selectedText: hasSelection ? currentSelection : '',
    precedingText,
    followingText,
    brandVoiceOptions,
    contentLanguage
  };
  const collaborationContext = getContentAssistantContextAsStringForCopilot(contentAssistantData);
  currentContextRef.current = collaborationContext;
  const messageAction = getCopilotActionFromContentAssistantData({
    contentAssistantData
  });
  sendActionFromAppToCopilot(messageAction);
}
function formatParameters(parameters) {
  const _ref = parameters !== null && parameters !== void 0 ? parameters : {},
    {
      promptThread
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  return Object.assign({}, rest, {
    promptThread: promptThread ? I18n.text(promptThread) : ''
  });
}