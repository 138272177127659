import { combineReducers } from 'redux';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { CUSTOM_FONTS_BY_ASSET_FETCH_FONTS, CUSTOM_FONTS_CLEAR_UPDATE_STATUS, CUSTOM_FONTS_CREATE_FONT, CUSTOM_FONTS_DELETE_FONT, CUSTOM_FONTS_EDIT_FONT, CUSTOM_FONTS_FETCH_FILE, CUSTOM_FONTS_FETCH_FONT, CUSTOM_FONTS_FETCH_FONTS, CUSTOM_FONTS_FILE_UPLOAD, GOOGLE_FONTS_CLEAR, GOOGLE_FONTS_FETCH_COMMON_FONTS, GOOGLE_FONTS_FETCH_FONT, GOOGLE_FONTS_FETCH_FONTS, SELECT_CUSTOM_FONT, SELECT_GOOGLE_FONT } from 'ContentData/actions/ActionTypes';
import { createSimpleRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
const fonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }
    case GOOGLE_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.family)) return state;
        return Object.assign({}, state, {
          [response.family]: response
        });
      }
    case GOOGLE_FONTS_CLEAR:
      {
        if (Object.keys(state).length) return {};
        return state;
      }
    default:
      return state;
  }
};
const selectedFonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }
    case SELECT_GOOGLE_FONT:
      {
        const {
          font
        } = action;
        const fontName = 'family' in font ? font.family : font.name;
        return Object.assign({}, state, {
          [fontName]: font
        });
      }
    default:
      return state;
  }
};
const commonFonts = (state = [], action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results;
      }
    default:
      return state;
  }
};
const customFontsByAsset = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          response,
          options
        } = action;
        const {
          path
        } = options || {};
        if (!(response && response.length && path)) return state;
        const fontsObj = response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state[path]));
        return Object.assign({}, state, {
          [path]: fontsObj
        });
      }
    default:
      return state;
  }
};
const customFontsByAssetRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          error,
          options,
          response
        } = action;
        const {
          path
        } = options || {};
        if (!path) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [path]: status
        });
      }
    default:
      return state;
  }
};
const customFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.reduce((hash, font) => {
          hash[font.name] = font;
          return hash;
        }, {});
      }
    default:
      return state;
  }
};
const selectedCustomFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.name]: response
        });
      }
    case CUSTOM_FONTS_BY_ASSET_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }
    case SELECT_CUSTOM_FONT:
      {
        const {
          font
        } = action;
        const fontName = 'family' in font ? font.family : font.name;
        return Object.assign({}, state, {
          [fontName]: font
        });
      }
    default:
      return state;
  }
};
const customFontToEdit = (state = null, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response;
      }
    case CUSTOM_FONTS_CLEAR_UPDATE_STATUS:
      {
        return null;
      }
    default:
      return state;
  }
};
const uploadCustomFontFileRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FILE_UPLOAD:
      {
        const {
          error,
          fileName,
          response
        } = action;
        if (!fileName) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [fileName]: status
        });
      }
    case CUSTOM_FONTS_CLEAR_UPDATE_STATUS:
      {
        return {};
      }
    default:
      return state;
  }
};
const uploadedCustomFontFilesByName = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FILE_UPLOAD:
      {
        const {
          fileName,
          response
        } = action;
        if (fileName && response && response.objects && response.objects.length) {
          return Object.assign({}, state, {
            [fileName]: response.objects[0]
          });
        }
        return state;
      }
    case CUSTOM_FONTS_FETCH_FILE:
      {
        const {
          fileName,
          response
        } = action;
        if (!response || !fileName) return state;
        return Object.assign({}, state, {
          [fileName]: response
        });
      }
    default:
      return state;
  }
};
const uploadedCustomFontFilesById = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FILE:
      {
        const {
          response,
          id
        } = action;
        if (!response || !id) return state;
        return Object.assign({}, state, {
          [id]: response
        });
      }
    default:
      return state;
  }
};
const fetchCustomFontFileRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FILE:
      {
        const {
          error,
          id,
          response
        } = action;
        if (!id) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [id]: status
        });
      }
    case CUSTOM_FONTS_CLEAR_UPDATE_STATUS:
      {
        return {};
      }
    default:
      return state;
  }
};
export default combineReducers({
  fonts,
  selectedCustomFonts,
  selectedFonts,
  commonFonts,
  commonFontsRequestStatus: createSimpleRequestStatusReducer(GOOGLE_FONTS_FETCH_COMMON_FONTS),
  customFontsByAsset,
  customFontsByAssetRequestStatus,
  customFonts,
  customFontToEdit,
  customFontsRequestStatus: createSimpleRequestStatusReducer(CUSTOM_FONTS_FETCH_FONTS),
  customFontRequestStatus: createSimpleRequestStatusReducer(CUSTOM_FONTS_FETCH_FONT, CUSTOM_FONTS_CLEAR_UPDATE_STATUS),
  createCustomFontRequestStatus: createSimpleRequestStatusReducer(CUSTOM_FONTS_CREATE_FONT, CUSTOM_FONTS_CLEAR_UPDATE_STATUS),
  deleteCustomFontRequestStatus: createSimpleRequestStatusReducer(CUSTOM_FONTS_DELETE_FONT, CUSTOM_FONTS_CLEAR_UPDATE_STATUS),
  editCustomFontRequestStatus: createSimpleRequestStatusReducer(CUSTOM_FONTS_EDIT_FONT, CUSTOM_FONTS_CLEAR_UPDATE_STATUS),
  uploadCustomFontFileRequestStatus,
  uploadedCustomFontFilesByName,
  uploadedCustomFontFilesById,
  fetchCustomFontFileRequestStatus
});