import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["onClick"];
import { getPluginSetting } from '../utils';
import { isUngated } from '../utils/settings';
import { removeSpanResetStateAndRemoveSlash
// @ts-expect-error Untyped module
} from 'tinymce-plugins/hsautocomplete/utils';
import { hsContentAssistantConfig, CONTENT_ASSISTANT_SETTINGS } from 'tinymce-plugins/constants/hscontentgeneration';
function getHighlightedTextContext(editor) {
  const editorContext = editor.getContent({
    format: 'text'
  });
  const highlightedText = editor.selection.getContent({
    format: 'text'
  });
  const indexOfSelectionStart = editorContext.indexOf(highlightedText);
  const indexOfSelectionEnd = indexOfSelectionStart + highlightedText.length;
  const precedingText = editorContext.slice(0, indexOfSelectionStart);
  const followingText = editorContext.slice(indexOfSelectionEnd + 1);
  return {
    editorContext,
    highlightedText,
    precedingText,
    followingText
  };
}
function getAutoCompleteContext(editor) {
  const range = editor.selection.getRng();
  const {
    fullText,
    precedingText,
    followingText
  } = getAnnotatedSelectionContextAsPlainTextAndRemoveSlashCommand(editor, range);
  return {
    editorContext: fullText,
    highlightedText: '',
    precedingText,
    followingText
  };
}
const TEMP_BEFORE_TEXT = '<<hstemp<<';
const TEMP_AFTER_TEXT = '>>hstemp>>';

// This util inserts delimiters into the existing DOM around the current selection, then
// asks Tiny to give us the content of the editor editor (including the delimiters) as text
// before removing the delimiter text nodes and putting the DOM back into the same state
// we started with.
function getEditorTextWithATemporaryAnnotatedRange(editor, range) {
  let textContext = '';
  editor.undoManager.ignore(() => {
    const duplicatedRange = range.cloneRange();
    const prefix = document.createTextNode(TEMP_BEFORE_TEXT);
    const suffix = document.createTextNode(TEMP_AFTER_TEXT);
    duplicatedRange.insertNode(prefix);
    duplicatedRange.collapse(false);
    duplicatedRange.insertNode(suffix);
    textContext = editor.getContent({
      format: 'text'
    });
    prefix.remove();
    suffix.remove();
    range.commonAncestorContainer.normalize();
  });
  return textContext;
}

// Changes the "<<hstemp<<" and ">>hstemp>>" delimiters back to a more sensible "<<" and ">>" to pass to prompts
export function getEditorTextWithAnnotatedRange(editor, range) {
  return getEditorTextWithATemporaryAnnotatedRange(editor, range).replace(TEMP_BEFORE_TEXT, '<<').replace(TEMP_AFTER_TEXT, '>>');
}

// This util calls the above util, but it also finds and removes the slash command that is (temporarily) present in the content.
function getAnnotatedSelectionContextAsPlainTextAndRemoveSlashCommand(editor, range) {
  const textContext = getEditorTextWithATemporaryAnnotatedRange(editor, range);
  const annotatedText = textContext.replace(/<<hstemp<<(.*)>>hstemp>>/g, '<<>>');
  const fullText = textContext.replace(/<<hstemp<<(.*)>>hstemp>>/g, '');
  const indexOfSelectionStart = textContext.indexOf(TEMP_BEFORE_TEXT);
  const indexOfSelectionEnd = textContext.indexOf(TEMP_AFTER_TEXT);
  const precedingText = textContext.slice(0, indexOfSelectionStart);
  const followingText = textContext.slice(indexOfSelectionEnd + TEMP_AFTER_TEXT.length);
  return {
    annotatedText,
    fullText,
    precedingText,
    followingText
  };
}
export function getBrandVoiceOptionsForCommand(editor, command) {
  const caSettings = getPluginSetting(editor, CONTENT_ASSISTANT_SETTINGS);
  return isUngated(editor, 'CoPilot:BrandVoiceIntegration') && command.brandVoiceLinkKey && caSettings !== null && caSettings !== void 0 && caSettings.brandVoiceOptions ? caSettings.brandVoiceOptions : undefined;
}
export function getContextFromTinymceForCommand(editor, command, commandType) {
  if (commandType === 'highlight') {
    const {
      editorContext,
      highlightedText,
      precedingText,
      followingText
    } = getHighlightedTextContext(editor);
    return {
      editorContext,
      precedingText,
      followingText,
      currentSelection: highlightedText,
      hasSelection: true
    };
  } else if (commandType === 'slash') {
    const {
      editorContext,
      precedingText,
      followingText
    } = getAutoCompleteContext(editor);
    return {
      editorContext,
      precedingText,
      followingText,
      currentSelection: '',
      hasSelection: false
    };
  }
  throw new Error(`Unknown command type: ${commandType}`);
}
export function createCopilotPayloadToExecuteCommand(editor, command, commandType) {
  const _ref = command,
    commandMinusHandler = _objectWithoutPropertiesLoose(_ref, _excluded);
  const {
    currentSelection,
    hasSelection,
    editorContext,
    precedingText,
    followingText
  } = getContextFromTinymceForCommand(editor, command, commandType);
  const brandVoiceOptions = getBrandVoiceOptionsForCommand(editor, command);
  return {
    command: commandMinusHandler,
    hasFocus: true,
    editorContext,
    currentSelection,
    hasSelection,
    precedingText,
    followingText,
    brandVoiceOptions,
    contentLanguage: getContentLangauge(editor)
  };
}
export function triggerFileManagerImageGeneration(editor) {
  editor.fire('hsimage:openGeneratePanel');
}
export function handleTextReplacement(editor, generatedText) {
  // const newLinesIncluded = generatedText.includes('\n');
  const processedContent = newlinesToParagraphs(generatedText);

  // TODO try and switch to swapCommand (will it work for highlight commands too?)
  insertTextAndSelect(editor, processedContent /*, newLinesIncluded */);
}
function attachHandlerToCommands(commands, handlerFunc, commandType) {
  // Take the command handler from the app and add some additional logic to it
  const onClick = (editorInEvent, command) => {
    additionalLogicForEveryCommandClick(editorInEvent, commandType);
    handlerFunc(editorInEvent, command, commandType);
  };
  return commands.map(c => Object.assign({}, c, {
    onClick
  }));
}
export function getSlashCommandsWithHandler(slashCommands, commandHandler) {
  return attachHandlerToCommands(slashCommands, commandHandler, 'slash');
}
export function getHighlightCommandsWithHandler(slashCommands, commandHandler) {
  return attachHandlerToCommands(slashCommands, commandHandler, 'highlight');
}
function additionalLogicForEveryCommandClick(editor, commandType) {
  if (commandType === 'slash') {
    removeSpanResetStateAndRemoveSlash(editor);
  }
}

// Not using the Editor type since it thinks many of the selection methods below don't exist
export function insertTextAndSelect(editor, processedContent) {
  const originalSelectionBookmark = editor.selection.getBookmark(2, true);
  editor.undoManager.transact(() => {
    editor.selection.setContent(processedContent);
  });
  const afterInsertCursorBookmark = editor.selection.getBookmark(2, true);
  const adjustedBookmark = {
    start: originalSelectionBookmark.start,
    end: afterInsertCursorBookmark.start
  };
  editor.selection.moveToBookmark(adjustedBookmark);
  editor.nodeChanged();
}
const isNotEmptyString = content => content.length > 0;
export function newlinesToParagraphs(content) {
  const splitContent = content.trim().split('\n');
  if (splitContent.length > 1) {
    return splitContent.filter(isNotEmptyString).map(line => `<p>${line}</p>`).join('');
  }
  return content.trim();
}
export function getIsHighlightingText(editor) {
  return editor.selection && !editor.selection.isCollapsed();
}
function getCopilotCollabContextValue(editor) {
  var _caSettings$hsContent;
  const caSettings = getPluginSetting(editor, CONTENT_ASSISTANT_SETTINGS, {});
  return (_caSettings$hsContent = caSettings[hsContentAssistantConfig.COPILOT_COLLAB_CONTEXT_VALUE]) !== null && _caSettings$hsContent !== void 0 ? _caSettings$hsContent : {};
}
export function getContentLangauge(editor) {
  var _getCopilotCollabCont;
  return (_getCopilotCollabCont = getCopilotCollabContextValue(editor).contentLanguage) !== null && _getCopilotCollabCont !== void 0 ? _getCopilotCollabCont : 'en';
}