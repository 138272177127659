// @ts-expect-error actionTypes not yet typed
import { UPDATE_AI_AB_TEST_FLOW_IS_ACTIVE } from '../actions/actionTypes';
import produce from 'immer';
export const AI_AB_TEST_FLOW_INITIAL_STATE = {
  isActive: false
};
export const aiAbTestFlowReducer = produce((draft, {
  type,
  payload
}) => {
  switch (type) {
    case UPDATE_AI_AB_TEST_FLOW_IS_ACTIVE:
      draft.isActive = payload;
      return draft;
    default:
      return draft;
  }
}, AI_AB_TEST_FLOW_INITIAL_STATE);