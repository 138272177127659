'use es6';

import { Map as ImmutableMap } from 'immutable';
import { keyIn, deepMergeWithoutListConcatenation } from 'ContentEditorUI/lib/immutableHelpers';
import { getAllFieldNamesOnModuleSpec } from 'ContentEditorUI/data/moduleUtils';
const EmptyMap = new ImmutableMap();
export const NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET = ['child_css', 'css', 'definition_id', 'smart_objects', 'smart_type', 'version', 'selectedSmartRuleId', 'merge_in_default_values', 'label'];
const ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET = NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET.concat(['body']);

// Schema only info, not needed on the params/body
export const SCHEMA_ONLY_ATTRIBUTES = ['label', 'type', 'overrideable', 'module_id', 'field_types', 'name', 'widget_name', 'path', 'parent_name', 'sourceContainsPostBody', 'has_uneditable_smart_rules', 'export_to_template_context', 'is_global', 'line_number', 'merge_in_default_values'

// TODO, also put `id` here (and remove from ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS)?
// TODO, also put `order` here?
// TODO, also put `tag` here?
// TODO, also put `wrap_field_tag` here?
// TODO, also put `body` here to prevent nested bodies?
// TODO, also put `label` here?
];
export const ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET = ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET.concat(SCHEMA_ONLY_ATTRIBUTES);
export const ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS = SCHEMA_ONLY_ATTRIBUTES.concat(['body',
// Acccidental that some layout_section_widgets have .body.body ?
'label',
// Same as ^, I think?
'section_type',
// Same as ^, I think?

// BE internal stuff
'_layout_section_depth', '_layout_section_last_row_depth', '_layout_section_row_number_at_current_depth', 'tag' // I think internal?

// Note, in layout_section_widgets, why is the order set at `w.body` and the root (but the root one is always 0)?

// TODO coalesce with PARAMS_TO_EXCLUDE_SET in layout-data-lib/static-1.4138/js/LayoutDataTree/serialize.js ?
]);
const filterOutSchemaOnlyKeys = (map, fieldNames = []) => {
  // Filter out all SCHEMA_ONLY_ATTRIBUTES _except_ if that is the name of a field in the custom module
  const attributesToFilter = SCHEMA_ONLY_ATTRIBUTES.filter(attribute => !fieldNames.includes(attribute));
  return map.filterNot(keyIn(...attributesToFilter));
};
export const attributesPickedFromContentWidget = (contentWidget, schemaWidget = EmptyMap, {
  sourcePrefix = '',
  moduleSpec,
  isUngatedToMergeTemplateSchemaBodyIntoContentBody = false
} = {}) => {
  const picked = {};
  let fieldNamesInSchema;
  if (moduleSpec) {
    fieldNamesInSchema = getAllFieldNamesOnModuleSpec(moduleSpec);
  }
  if (contentWidget) {
    const attributes = schemaWidget.isEmpty() ? ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET : ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET;
    attributes.forEach(attr => {
      // Layout section attributes come from params (hence the optional prefix)
      const source = sourcePrefix ? contentWidget.get(sourcePrefix) : contentWidget;
      if (source.has(attr)) {
        picked[attr] = source.get(attr);
        if (attr === 'body' && isUngatedToMergeTemplateSchemaBodyIntoContentBody) {
          // Clean up module bodies that have incorrectly had things like module_id, type, etc set on them
          picked[attr] = filterOutSchemaOnlyKeys(picked[attr], fieldNamesInSchema);
          const schemaBody = schemaWidget.get(attr);
          if (schemaBody) {
            const filteredSchemaBody = filterOutSchemaOnlyKeys(schemaBody, fieldNamesInSchema);
            picked[attr] = deepMergeWithoutListConcatenation(filteredSchemaBody, picked[attr]);
          }
        }
        if (attr === 'child_css') {
          ['input', 'button'].forEach(childModule => {
            if (picked.child_css && picked.child_css[childModule]) {
              delete picked[attr][childModule][childModule];
            }
          });
        }
      }
    });
  }
  return picked;
};

/* eslint-disable consistent-return */
export const getContentWidgetForWidgetOutsideContainer = (widgetSchema, contentWidgets) => {
  const contentWidget = contentWidgets && widgetSchema && contentWidgets.get(widgetSchema.get('name'));
  if (contentWidget && !contentWidget.get('deleted_at')) {
    return contentWidget;
  }
};
/* eslint-enable consistent-return */

// These functions are yet another iteration (from now removed `normalizedIdentifiers`) that has
// baby-stepped us slightly more towards a world that is more based on module _names_ (but still
// defers to kinda weird old logic that may only be needed for flex columns)
const findIntegerId = (schemaWidget, contentWidget) => {
  if (schemaWidget == null || schemaWidget.isEmpty()) {
    return contentWidget.get('id');
  } else if (typeof schemaWidget.get('key') === 'number') {
    return schemaWidget.get('key'); // this should match contentWidget.id
  }
  return undefined;
};

// The "key" identifier seems to mostly be a legacy identifier, that is referenced some in older
// Backbone-ish widget model/collection code. Though we believe it is still necessary for flex columns,
// because name and id are not always there (only when added? only in the schema/default content?).
export const normalizedKey = (schemaWidget, contentWidget) => {
  return findIntegerId(schemaWidget, contentWidget) || schemaWidget.get('name');
};
export const normalizedId = (schemaWidget, contentWidget) => {
  const integerId = findIntegerId(schemaWidget, contentWidget);

  // "name" is the preferred indentifier, but fall back to
  //  - `widget_${key}` from schema if it exists
  //  - and then an ID on the "content widget" if the schema is missing IDs or empty
  //
  // We believe name should be set most of the time, except for...?
  //  - New/default modules in flex columns?
  //  - Old data from long ago?
  if (schemaWidget && schemaWidget.get('name')) {
    return schemaWidget.get('name');
  } else if (integerId) {
    return `widget_${integerId}`;
  } else if (contentWidget && contentWidget.get('id')) {
    return contentWidget.get('id');
  }
  return undefined;
};
export const defaultContentWidgetAttributes = (schemaWidget = EmptyMap, contentWidget = EmptyMap) => {
  return ImmutableMap({
    css: contentWidget.get('css') || schemaWidget.get('css') || EmptyMap,
    child_css: contentWidget.get('child_css') || schemaWidget.get('child_css') || EmptyMap,
    styles: contentWidget.get('styles') || EmptyMap,
    smart_type: contentWidget.get('smart_type') || schemaWidget.get('smart_type') || null
  });
};
const isV1LogoBuiltin = widgetSchema => {
  return widgetSchema.get('type') === 'logo';
};
const fixupLogoWidgetForEditor = widgetSchema => {
  if (widgetSchema.getIn(['body', 'src'])) {
    return widgetSchema.get('body').merge(Object.assign({}, widgetSchema.body, {
      override_inherited_src: true
    }));
  } else if (!widgetSchema.getIn(['body', 'override_inherited_src'])) {
    return widgetSchema.merge(Object.assign({}, widgetSchema.body, {
      alt: '',
      src: null
    })).remove('width').remove('height');
  }
  return widgetSchema;
};
export const getWidgetBodyFromSchema = (widgetSchema = EmptyMap) => {
  if (widgetSchema.get('body')) {
    if (isV1LogoBuiltin(widgetSchema)) {
      return fixupLogoWidgetForEditor(widgetSchema);
    }
    return widgetSchema.get('body');
  }
  return EmptyMap;
};