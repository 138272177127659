'use es6';

import tinymce from 'tinymce';
import { getAtomValue } from '../data/atomUtils';
import { activeAutocompleteCommandAtom, autocompleteActiveRangeAtom, autocompleteCommandIsActiveAtom, resetAutocompleteAtomState, updateActiveAutocompleteCommand, updateAutocompleteActiveRange, updateAutocompleteCommandIsActiveAtom, updateFilteredAutocompleteCommands } from '../data/autocompleteAtoms';
import { isWhitespace } from '../utils';
import { BOGUS_DATA_ATTR } from '../utils/dom';
const autocompleteSpan = document.createElement('span');
autocompleteSpan.setAttribute('data-mce-hs-autocomplete', 1);
autocompleteSpan.setAttribute(BOGUS_DATA_ATTR, 1);
export const AUTOCOMPLETE_MODES = {
  COMMAND: 'COMMAND',
  QUICK_INSERT: 'QUICK_INSERT'
};
export function removeAutocompleteSpanTarget(editor) {
  editor.dom.remove(autocompleteSpan);
}
export function getAutocompleteSpan() {
  return autocompleteSpan;
}
function isIndexStartOfWord(index, text) {
  if (index === 0) {
    return true;
  }
  const previousChar = text.charAt(index - 1);
  return isWhitespace(previousChar);
}
function findTriggerChar(trigger, offset, text, mode) {
  let i;
  const isInCommandMode = mode === AUTOCOMPLETE_MODES.COMMAND;
  for (i = offset - 1; i >= 0; i--) {
    const char = text.charAt(i);
    if (isWhitespace(char) && !isInCommandMode) {
      return offset;
    }
    if (char === trigger && isIndexStartOfWord(i, text)) {
      return i;
    }
  }
  return -1;
}
export function findActiveTriggerRange(editor, triggers, mode = AUTOCOMPLETE_MODES.QUICK_INSERT) {
  const currentRange = editor.selection.getRng();
  const textSeeker = tinymce.dom.TextSeeker(editor.dom);
  let activeAutocompleteRange;
  const activeTrigger = triggers.find(trigger => {
    const triggerPosition = textSeeker.backwards(currentRange.startContainer, currentRange.startOffset, (__textNode, offset, text) => findTriggerChar(trigger, offset, text, mode));
    if (!triggerPosition) {
      return false;
    }
    const autocompleteRange = currentRange.cloneRange();
    autocompleteRange.setStart(triggerPosition.container, triggerPosition.offset);
    autocompleteRange.setEnd(currentRange.endContainer, currentRange.endOffset);
    if (autocompleteRange.collapsed) {
      return false;
    }
    activeAutocompleteRange = autocompleteRange;
    const rangeText = autocompleteRange.toString();
    const triggerCharIndex = rangeText.lastIndexOf(trigger);
    return triggerCharIndex === 0;
  });
  if (!activeTrigger) {
    activeAutocompleteRange = null;
  }
  return {
    activeTrigger,
    activeAutocompleteRange
  };
}
export function gatherFilteredCommands(commandFilters, autocompleteTextWithoutTrigger) {
  return commandFilters.reduce((filteredCommands, commandFilter) => {
    // Maybe slow?
    const newCommands = commandFilter(autocompleteTextWithoutTrigger);
    return filteredCommands.concat(newCommands);
  }, []);
}
export function keepIndexBetweenArrayBounds(indexToCheck, arrLength) {
  const indexCappedAtEnd = Math.min(indexToCheck, arrLength - 1);
  return Math.max(0, indexCappedAtEnd);
}
export function swapCommandForContent(editor, content) {
  const autocompleteRange = getAtomValue(autocompleteActiveRangeAtom);
  const isInsertingOnEmptyLine = !autocompleteRange.endContainer.textContent;
  if (autocompleteRange) {
    editor.undoManager.transact(() => {
      // if its inserting on an empty line, there is no range to set, and setting one will
      // break the insertion
      if (!isInsertingOnEmptyLine) {
        editor.selection.setRng(autocompleteRange);
      }
      editor.selection.setContent(content);
    });
  } else {
    // Abort inserting content if autocomplete range has changed
    // This may happen when using a popover that doesn't have a mce-* class
    console.warn('No autocomplete range found, aborting content insertion');
  }
  resetAutocompleteAtomState();
  removeAutocompleteSpanTarget(editor);
}
export function getTextForRangeWithoutTrigger(range, removeNonBreakingSpaces = false) {
  const rangeText = range.toString();
  const textWithoutTrigger = rangeText.substring(1);
  if (removeNonBreakingSpaces) {
    return textWithoutTrigger.replaceAll(String.fromCharCode(160), ' ');
  }
  return textWithoutTrigger;
}

// Setting a span, and storing the active range - for popovers placement
export const updateAutocompleteActiveRangeAndSetSpan = (editor, range) => {
  const isInsertingOnEmptyLine = !range.endContainer.textContent;
  range.insertNode(getAutocompleteSpan());
  range.setStartAfter(getAutocompleteSpan());
  updateAutocompleteActiveRange(range);
  // Don't update the selection for an empty line - will create an issue at insertion
  if (!isInsertingOnEmptyLine) {
    editor.selection.setCursorLocation(range.endContainer, range.endOffset);
  }
};
export const openAutocompleteInlineDropdownMenu = (editor, filteredCommands, activeAutocompleteRange) => {
  const autocompleteCommandIsActive = getAtomValue(autocompleteCommandIsActiveAtom);
  const currentActiveAutocompleteCommandIndex = getAtomValue(activeAutocompleteCommandAtom);
  const activeCommandIndexBoundToUpdatedCommands = keepIndexBetweenArrayBounds(currentActiveAutocompleteCommandIndex, filteredCommands.length);
  updateActiveAutocompleteCommand(activeCommandIndexBoundToUpdatedCommands);
  updateFilteredAutocompleteCommands(filteredCommands);
  if (!autocompleteCommandIsActive) {
    updateAutocompleteActiveRangeAndSetSpan(editor, activeAutocompleteRange);
    updateAutocompleteCommandIsActiveAtom(true);
    updateActiveAutocompleteCommand(0);
  }
};
export function getAutocompleteCommandTriggers(editor) {
  const autocompleteTriggerMap = editor.autoCompleteCommands;
  return Object.keys(autocompleteTriggerMap);
}
export function removeSpanAndResetState(editor) {
  removeAutocompleteSpanTarget(editor);
  resetAutocompleteAtomState();
}
export function removeSpanResetStateAndRemoveSlash(editor) {
  const nodeAfterSpan = autocompleteSpan.nextSibling;
  const textAfterSpan = nodeAfterSpan === null || nodeAfterSpan === void 0 ? void 0 : nodeAfterSpan.textContent;

  // Replace the slash text node with a &nbsp; or with a br, depending on whether
  // there is other text in the current paragraph (so the line break doesn't
  // disappear)
  if (textAfterSpan !== null && textAfterSpan !== void 0 && textAfterSpan.startsWith('/')) {
    const parentText = autocompleteSpan.parentElement.textContent;
    if (parentText === textAfterSpan) {
      editor.dom.replace(document.createElement('br'), nodeAfterSpan);
    } else {
      nodeAfterSpan.textContent = ' '; // &nbsp;
    }
  }
  removeSpanAndResetState(editor);
}