'use es6';

import tinymce from 'tinymce';
import { atom, swap } from 'atom';
import { getAtomValue, useAtomValue } from './atomUtils';
export const autocompleteQuickInsertIsActiveAtom = atom(false);
export const filteredAutocompleteCommandsAtom = atom(null);
export const activeAutocompleteCommandAtom = atom(null);
export const autocompleteEscapedAtom = atom(false);
export const autocompleteActiveRangeAtom = atom(null);
export const autocompleteCommandIsActiveAtom = atom(false);
export const charsOffFromResultAtom = atom(0);
export const autocompleteResultPopoverIsActiveAtom = atom(false);
export function updateAutocompleteQuickInsertIsActiveAtom(isActive) {
  swap(autocompleteQuickInsertIsActiveAtom, () => isActive);
}
export function updateFilteredAutocompleteCommands(newCommands) {
  swap(filteredAutocompleteCommandsAtom, () => newCommands);
}
export function updateAutocompleteActiveRange(activeRange) {
  swap(autocompleteActiveRangeAtom, () => activeRange);
}
export function updateAutocompleteCommandIsActiveAtom(value) {
  swap(autocompleteCommandIsActiveAtom, () => value);
}
export function incrementCharsOffFromResultAtom() {
  swap(charsOffFromResultAtom, n => n + 1);
}
export function decrementCharsOffFromResultAtom() {
  swap(charsOffFromResultAtom, n => n - 1);
}
export function resetCharsOffFromResultAtom() {
  swap(charsOffFromResultAtom, () => 0);
}
export function openAutocompleteResultPopover() {
  swap(autocompleteResultPopoverIsActiveAtom, () => true);
}
export function closeAutocompleteResultPopover() {
  swap(autocompleteResultPopoverIsActiveAtom, () => false);
}
export function updateActiveAutocompleteCommand(index) {
  swap(activeAutocompleteCommandAtom, () => index);
}
export function updateAutocompleteEscaped(value) {
  swap(autocompleteEscapedAtom, () => value);
}
export function useAutoCompleteIsActive() {
  const autocompleteQuickInsertIsActive = useAtomValue(autocompleteQuickInsertIsActiveAtom);
  const isCommandActive = useAtomValue(autocompleteCommandIsActiveAtom);
  return autocompleteQuickInsertIsActive || isCommandActive;
}
export function useFilteredAutocompleteCommands() {
  const filteredCommands = useAtomValue(filteredAutocompleteCommandsAtom);
  return filteredCommands;
}
export function useActiveAutcompleteCommand() {
  return useAtomValue(activeAutocompleteCommandAtom);
}
export function updateCharsOffFromResultBasedOnKeyPressed(key) {
  if (key === 'Backspace') {
    decrementCharsOffFromResultAtom();
    return;
  }
  incrementCharsOffFromResultAtom();
}
export function useIsAutocompleteResultPopoverOpen(editor) {
  const editorIsActive = editor.id === tinymce.activeEditor.id;
  const popoverIsOpen = useAtomValue(autocompleteResultPopoverIsActiveAtom);
  return editorIsActive && popoverIsOpen;
}

// Copilot collab _only_ changes

export const copilotCollabBookmarkId = atom(null);
export function updateCopilotCollabBookmarkId(id) {
  swap(copilotCollabBookmarkId, () => id);
}
export const getBookmarkEndElement = editor => {
  const currentBookmarkId = getAtomValue(copilotCollabBookmarkId);
  if (!currentBookmarkId) {
    return null;
  }
  return editor.dom.get(`${currentBookmarkId}_end`);
};

// Changes for both current plugin and copilot collab

export function resetAutocompleteAtomState() {
  // `updateBookmarkId` is only used in copilot collab, but won't hurt existing CA code path
  updateCopilotCollabBookmarkId(null);
  updateAutocompleteQuickInsertIsActiveAtom(false);
  updateFilteredAutocompleteCommands(null);
  updateAutocompleteActiveRange(null);
  resetCharsOffFromResultAtom();
  updateAutocompleteCommandIsActiveAtom(false);
  closeAutocompleteResultPopover();
}

// END Copilot collab changes